








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import ProductItem from '@/app/modules/products/components/partials/list/ProductItem.vue';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import DataPagination from '@/shared/resources/components/DataPagination.vue';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import Pagination from '@/core/support/pagination/Pagination';
import Loader from '@/shared/resources/components/Loader.vue';
import { scrollToTop } from '@/shared/lib/helpers/scrollTo';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import Alert from '@/shared/resources/components/Alert.vue';
import LoaderOverlay from '@/shared/resources/components/LoaderOverlay.vue';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';

@Component({
  components: {
    LoaderOverlay,
    Alert,
    Loader,
    DataPagination,
    ProductItem,
    Card,
    GridCol,
    GridRow,
  },
})
export default class ProductsList extends Vue {
  /**
   * Props
   */
  @Prop() private categoryId!: number | null;
  @Prop() private searchQuery!: string | null;
  @Prop({ default: 'list' }) private view!: string;

  /**
   * Data
   */
  private products: ORMCollection = new ORMCollection();
  private initialLoading: boolean = false;
  private initiated: boolean = false;
  private loading: boolean = false;
  private error: boolean = false;

  /**
   * Getters
   */
  private get pagination(): Pagination {
    return this.products.pagination;
  }

  private get category(): CategoryCoreModel | null {
    if (!this.categoryId) {
      return null;
    }

    return CategoryCoreModel.find(this.categoryId);
  }

  private get message(): string | null {
    if (!this.loading && this.products.empty()) {
      return !!this.searchQuery
          ? 'Brak wyników wyszukiwania. Zmień zapytanie.'
          : 'Brak produktów w wybranej kategorii.';
    }

    return null;
  }

  /**
   * Display getters
   */
  private get displayList(): boolean {
    return !this.initialLoading;
  }

  private get displayInitialLoader(): boolean {
    return this.initialLoading && !this.initiated;
  }

  private get displayLoader(): boolean {
    return this.loading && this.initiated;
  }

  private get displayError(): boolean {
    return this.error;
  }

  private get displayMessage(): boolean {
    return !!this.message;
  }

  private get displayPagination(): boolean {
    return this.pagination.getTotal() > this.pagination.getLimit();
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'products-list': true,
      ['products-list--' + this.view + '-view']: true,
    };
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.fetchData();
  }

  /**
   * Methods
   */
  private async fetchData() {
    this.initialLoading = true;
    this.error = false;

    try {
      await this.fetchProducts(true);
      this.initiated = true;
    } catch (ex) {
      // TODO
      this.error = true;
    }

    this.initialLoading = false;
  }

  private async fetchProducts(nested: boolean = false) {
    this.loading = true;
    this.error = false;

    try {
      const query: ApiORMQueryBuilderExtended = ProductCoreModel
          .request()
          .where('online', true)
          .page(this.pagination.getPage(1)) // TODO
          .limit(this.pagination.getLimit(30)) // TODO
          .sort({ field: 'availability', desc: 'asc' })
          .with('tags');

      if (!!this.searchQuery) {
        query.where('search', this.searchQuery);
      } else {
        query.where('categoryId', this.categoryId);
      }

      this.products = await query.fetch();

    } catch (ex) {
      if (nested) {
        throw ex;
      } else {
        // TODO
        this.error = true;
      }
    }

    this.loading = false;
  }

  /**
   * Handlers
   */
  private onPageUpdate(page: number) {
    this.pagination.setPage(page);
    this.fetchProducts();

    scrollToTop();
  }

  /**
   * Watchers
   */
  @Watch('categoryId')
  private async watchCategoryIdChange(categoryId: number | null) {
    this.pagination.setPage(1);
    await this.fetchProducts();
  }

  @Watch('category')
  private async watchCategoryChange(category: CategoryCoreModel | null) {
    this.$emit('get:category', category);
  }

  @Watch('searchQuery')
  private async watchSearchQueryChange(searchQuery: null) {
    this.fetchData();
  }
}
