










import { Component, Prop, Vue } from 'vue-property-decorator';

import Pagination from '@/core/support/pagination/Pagination';

@Component
export default class DataPagination extends Vue {
  /**
   * Props
   */
  @Prop() private pagination!: Pagination;
  @Prop({ default: 7 }) private totalVisible!: number;
  @Prop({ default: false, type: Boolean }) private disabled!: boolean;
  @Prop({ default: false, type: Boolean }) private minimal!: boolean;

  /**
   * Handle current page
   */
  private get currentPage() {
    return this.pagination.getPage();
  }

  private set currentPage(page: number) {
    this.$emit('update', page);
  }

  /**
   * Getters
   */
  private get totalRows(): number {
    return this.pagination.getTotal();
  }

  private get perPage(): number {
    return this.pagination.getLimit();
  }

  private get length(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }
}
